<template>
  <div>
    <!-- <loading
      :active.sync="isLoading"
      :can-cancel="true"
      :is-full-page="fullPage"
    ></loading> -->

    <div class="row text-center">
      <div
        :style="{
          height: '300px',
          marginTop: '-10px',
          backgroundImage: 'url(https://software.mohasagor.com/public/images/mm.jpg)',
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
        }"
        class="background_image"
      ></div>
    </div>

        <div class="team_container">
            <div class="row">
            <div v-for="(member, index) in team" :key="index" class="col-md-3 col-lg-3 col-xs-12">
            <div class="card profile_container text-center">
              <div class="inside_top"></div>
              <img class="profile_image" :src="img_base_url + member.avator" />
              <div class="inside_bottom"></div>
              <h4 class="heading">{{ member.name }}</h4>
              <p>{{ member.designation }}</p>
            </div>
          </div>
        </div>
    </div>

  </div>
</template>
<script>
// import Loading from "vue-loading-overlay";
// import "vue-loading-overlay/dist/vue-loading.css";
export default {
  created() {
    this.getTeam();
  },
  data() {
    return {
      isLoading: true,
      fullPage: true,
      team: "",
    };
  },
  methods: {
    getTeam() {
      this.axios
        .get("/team/members")
        .then((resp) => {
        //  console.log(resp);
          if (resp.data.status == "OK") {
            this.team = resp.data.team;
            // this.isLoading = false;
          }
        })
    },
  },
  components: {
    // Loading,
  },
};
</script>

<style scoped>
.team_container {
  widows: 95%;
  margin:0% 5%;
  margin-bottom: 5%;
}

.profile_image {
  margin-top: -70px;
  margin-left: 20%;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 5px solid #fff;
}
.desc_info p {
  padding: 5px;
  margin: 10px;
  padding-bottom: 20px;
}

.profile_container {
  border-radius: 10px;
  margin-top: 50px;
  background: #fff;
  padding-bottom: 20px;
}

.inside_top {
  width: 100%;
  height: 90px;
  background: #ff4d03;
  border-radius: 5px;
}

.inside_bottom {
  width: 100%;
  background-color: #fff;
}
h4 {
  font-size: 16px;
}
p {
  font-size: 14px;
  line-height: 10px;
}
</style>
